body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background-color: #e9e9e9;

}

button {
  background-color: var(--primary-button-color, #28a745);
  color: var(--primary-button-text-color, #fff);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.button-quanitty {
  background-color: lightblue;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 2px;
}

.button.round {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.mainContainer {
  padding-top: 30px;
  padding-left: 16px;
  padding-right: 16px;
}


#header a {
  float: left;
  color: #000;
  text-align: center;
  padding: 14px 16px;

  text-decoration: none;
  font-size: 17px;

}

#header a:hover {
  background-color: #008000;
  color: #fff;
}

#header a.active {
  /*background-color: #1AA7EC;*/
  background-color: #008000;
  color: #fff;
}

#header .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  #header a:not(:first-child) {
    display: none;
  }

  #header a:first-child {
    float: none;
    display: block;
    text-align: left;
  }

  #header.responsive {
    position: relative;
  }

  #header .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  #header.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  #header a.icon {
    float: right;
    display: block;
  }
}

@media screen and (min-width: 600px) {

  #header {
    min-height: 49px;
  }

  .mainContainer {
    padding: 0;
    margin: auto;
    max-width: 800px;
  }

}

.fixed-footer {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fixed-footer {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(90% - 20px);
  max-width: 90%;
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);
  border-radius: 5px;
  padding: 12px 10px;
  z-index: 999;
  background-color: lightblue;
}

.footer-link {
  color: var(--primary-button-text-color);
  text-decoration: none;
  width: 100%;
  display: block;
}

.footer-icon {
  font-size: 24px;
  margin-right: 10px;
  vertical-align: middle;
}

/*Main*/

.menu-items {
  text-align: left;
  margin-bottom: 150px;
  margin-top: 30px;
}

.menu-category {
  margin-bottom: 40px;
  background-color: #f0f0f0;
  padding: 10px;
}

.menu-category h2 {
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  font-size: 24px;
}

.menu-category ul {
  list-style-type: none;
  padding: 0;
}

.menu-item {
  margin-bottom: 20px;
  padding-top: 10 px;
  position: relative;
  height: 30px;
  display: table;
  width: 100%;
}

.menu-item-row {
  display: table-row;
}

.menu-item-info {
  display: table-cell;
  width: 80%;
  border-bottom: solid 2px #E9E9E9;  
  padding-right: 5px;
}
.menu-item-info-description{
  font-size: 12px;
}

.menu-item-info .name {
  font-weight: 700;
}

.menu-item-price {
  display: table-cell;
  border-bottom: solid 2px #E9E9E9;
  min-width: 80px;
}

.menu-item-other{
  display: table-cell;
  min-width: 62px;
  border-bottom: solid 2px #E9E9E9;
}
.menu-item-other .button{
  float: left
}

.section-info {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: left;
}

.section-info p {
  font-size: 0.9em;
}

.section-info h2 {
  font-size: 1.2em;
}
/*End Menu*/

/*Checkout*/

.checkout-menu-items {
  text-align: left;
  margin-bottom: 150px;
  margin-top: 30px;
}

.checkout-menu-category {
  margin-bottom: 40px;
  background-color: #f0f0f0;
  padding: 10px;
}

.checkout-menu-category h2 {
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
  font-size: 24px;
}

.checkout-menu-category ul {
  list-style-type: none;
  padding: 0;
}

.checkout-menu-category li{
  border-bottom: solid 2px #E9E9E9;
  padding-bottom: 5px;
}

.checkout-menu-item {
  margin-bottom: 20px;
  padding-top: 10 px;
  position: relative;
  height: 30px;
  display: table;
  width: 100%;
}

.checkout-menu-item-row {
  display: table-row;
}

.checkout-menu-item-info {
  display: table-cell;
  width: 80%;
}

.checkout-name {
  font-weight: 700;
}

.checkout-menu-item-price {
  display: table-cell;
  
  min-width: 80px;
}

.place-order-section {
  background-color: #f0f0f0;
  text-align: left;
  padding: 10px;
  margin: 10px;
}

.place-order-section p {
  font-size: 0.9em;
}

.checkout-section {
  background-color: #f0f0f0;
  text-align: left;
  padding: 10px;
  margin: 10px;
}

.checkout-section p {
  font-size: 0.9em;
}

.checkout-section h2 {
  font-size: 1.2em;
}

.checkout-total-price {
  text-align: left;
}

.checkout-menu-add-remove{
  text-align: right;
  margin-right: 20px;
  padding-top: 10px;;
}
.checkout-menu-add-remove-remove{
  display: table-cell;  
}
.checkout-menu-add-remove-amount{
  display: table-cell;
}
.checkout-menu-add-remove-add{
  display: table-cell;
}
.checkout-quantity-input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 15px;
  margin: 10px;
}

@media screen and (min-width: 600px) {
  .checkout-menu-add-remove-remove{
    display: none;
  }
  .checkout-menu-add-remove-add{
    display: none;
  }
  .checkout-quantity-input{
    width: 40px;
    margin: 0;
    text-align: center;
  }
  .checkout-menu-item-price{
    text-align: right;
  }
}

/*End Menu checkout*/



.quantity-badge {
  background-color: lightblue;
  color: var(--primary-button-text-color, #fff);
  padding: 5px 8px;
  border-radius: 50%;
  margin-right: 15px;
  font-weight: 500;
  top: 0.5em;
  position: relative;
}

.button-remove {
  background-color: var(--danger-button-color);
  color: var(--danger-button-text-color);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: 10px;
}