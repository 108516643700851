.status-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.day-label {
  font-weight: bold;
  margin-right: 10px;
}

.closed-message {
  margin: 20px;
  display: block;
}

.opening-hours {
  margin: 30px;
}