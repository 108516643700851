.cookie-consent-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 20px 0;
    text-align: center;
    z-index: 999;
}

.cookie-consent-content {
    margin: 0 auto;
    width: 80%;
}

.cookie-consent-text {
    color: #fff;
    margin-bottom: 10px;
}

.cookie-consent-link {
    color: #fff;
}

.cookie-consent-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}
