.order-container {
    margin-left: 20px;
    margin-top: 20px;
}

.order-status-message {
    background-color: #111;
    color: #fff;
    padding: 20px;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 52px;
}

.order-ready {
    color: #333;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #ccc;
    width: fit-content;
}

.back-to-menu {
    margin-left: 20px;
    margin-top: 20px;
    text-decoration: none;
    background-color: var(--primary-button-color);
    color: var(--primary-button-text-color);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    display: inline-block;
    cursor: pointer;
}