h1 {
    text-align: left;
    flex: 1;
    padding-left: 20px;
}

.container {
    padding-left: 20px;
}

.meal-list {
    list-style: none;
    padding: 0;
}

.meal-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.meal-name,
.meal-description,
.meal-price {
    margin-right: 10px;
    flex: 1;
}

.button-none {
    border: none;
    background: none;
    margin-right: 5px;
}

.hr-margin {
    margin: 20px;
}

.options {
    margin: 20px;
    display: flex;
    justify-content: space-between;
}

.select-delivery {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
}

form {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    max-width: 400px;
}

.form-group {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.form-label {
    font-size: 14px;
    margin-bottom: 5px;
}

.form-input,
textarea {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 20px;
}

.comments {
    min-height: 50px;
}

.form-actions {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
}

.link-button,
.submit-button {
    text-decoration: none;
    border: none;
    border-radius: 5px;
    padding: 8px 10px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    font-size: small;
}
