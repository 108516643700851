.dialog {
    top: 0;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #e9e9e9;    
    border: none;
}

@media screen and (min-width: 600px) {
    .dialog{
        margin: auto;
        max-width: 800px;
        padding: 10px;
    }
}

.dialog-body {
    overflow-y: auto;
}

.dialog-list-item {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 5px;
}

.dialog-list-item h3 {
    border-bottom: 2px solid #000;
}

.list-item {
    margin-bottom: 10px;
}

.variant-list {
    list-style: none;
    padding-left: 0;

}

.variant-item {
    margin-bottom: 5px;
}

.variant-label {
    cursor: pointer;
}

.checkbox-label {
    cursor: pointer;
}

.primary-button {
    background-color: var(--primary-button-color, #28a745);
    color: var(--primary-button-text-color, #fff);
    border: none;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 5px;
}

.secondary-button {
    background-color: #f9f9f9;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}